<template>
  <div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="ip" label="ip" width="120"> </el-table-column>
      <el-table-column prop="address" label="地区"> </el-table-column>
      <el-table-column prop="browser" label="浏览器"> </el-table-column>
      <el-table-column prop="system" label="系统"> </el-table-column>
      <el-table-column prop="nums" label="请求次数"> </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              @click="showDrawer(scope)"
              type="primary"
              size="small"
              icon="el-icon-search"
              >url</el-button
            >
            <el-button
              @click="edit(scope)"
              type="primary"
              size="small"
              icon="el-icon-edit"
              >拉黑</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
      :title="showUrl.ip"
      :visible.sync="show"
      direction="rtl"
      :size="this.$store.getters.isPCM ? '70%' : '50%'"
    >
      <el-table :data="showUrl.uris" border style="width: 90%; margin: 0 auto">
        <el-table-column prop="url" label="路径"></el-table-column>
        <el-table-column prop="time" label="时间"></el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
export default {
  created() {
    //定时更新 最新运行时间
    const that = this;
    this.getData(that);
  },
  data() {
    return {
      show: false,
      showUrl: {
        ip: "",
        uris: [],
      },
      tableData: [],
    };
  },
  methods: {
    getData(that) {
      this.$axios.get("/adminApi/visitors").then(function (resp) {
        const data = resp.data.data;
        data.forEach((item) => {
          item.nums = Object.keys(item.uris).length;
        });
        that.tableData = data;
      });
    },
    showDrawer(scope) {
      this.showUrl.ip = scope.row.ip;
      for (let key in scope.row.uris) {
        this.showUrl.uris.push({
          url: scope.row.uris[key],
          time: this.common.dateFormatter(key),
        });
      }
      this.show = true;
    },
    edit(scope) {
      let that =this;
      this.$axios
        .post("/adminApi/addBlackVisitor", {
          // id: 1,
          ip: scope.row.ip,
          system: scope.row.system,
          browser: scope.row.browser,
          reason: "未知",
          address:scope.row.address,
          insertTime: new Date(),
        })
        .then(function (resp) {
          if (resp.data.code === 200) {
            that.$notify.success({
              title: resp.data.msg,
            });
          } else {
            that.$notify.error({
              title: resp.data.msg,
            });
          }
        });
    },
  },
};
</script>

<style>
</style>